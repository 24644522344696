import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";

const SEO = ({ type, title, desc, image, slug }) => {
  const {
    site: {
      siteMetadata: { short_name, siteUrl, description }
    }
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          short_name
          siteUrl
        }
      }
    }
  `);

  const htmlTitle = `${title} • ${short_name}`;
  const ogImage = image ? image : `${siteUrl}/static/images/social/opengraph.png`
  const metaDescription = desc || description;

  return (
    <React.Fragment>
      <title>{htmlTitle}</title>
      <link rel="canonical" href={`${siteUrl}/${slug}`} />
      <meta name="description" content={metaDescription} />
      <meta name="image" content={`${ogImage}`} />
      <meta name="image:alt" content={metaDescription} />

      {/* Facebook */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={htmlTitle} />
      <meta property="og:url" content={`${siteUrl}/${slug}`} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={`${ogImage}`} />
      <meta property="og:image:alt" content={metaDescription}></meta>

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={htmlTitle} />
      <meta name="twitter:url" content={`${siteUrl}/${slug}`} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={`${ogImage}`} />
      <meta name="twitter:image:alt" content={metaDescription}></meta>
    </React.Fragment>
  );
};

SEO.propTypes = {
  /** The type of meta - useful for Facebook */
  type: PropTypes.oneOf(['website', 'article']),
  /** The site title */
  title: PropTypes.string.isRequired,
  /** The site description */
  desc: PropTypes.string,
  /** The slug URL */
  slug: PropTypes.string.isRequired,
  /** Image url to use for opengraph image */
  image: PropTypes.string,
};

SEO.defaultProps = {
  type: 'website'
};

export default SEO;
