import React from "react";

// components
import SEO from "@components/seo";
import { Link } from "gatsby";

function SuccessPage() {
  return (
    <React.Fragment>
      <section className="content text-f4">
        <header className="py-64 lg:mt-24 content">
          <h1 className="relative z-10 mb-0 text-f2 xs:text-f1 xxl:text-headline">Message sent</h1>
        </header>
        <p className="text-f3 text-center pb-32">Thanks for your message, I will get back to you shortly. Now why not… <Link to="/work/" className="text-jasmine hover:text-sidecar transition duration-200 ease-in-out">browse some work</Link>?</p>
      </section>
    </React.Fragment>
  );
}

export const Head = () => {
  return <SEO title="Message sent" slug="success" />;
}

export default SuccessPage;
