/* eslint react/no-unknown-property: 0 */
import React from "react";

// components
import SEO from "@components/seo";
import Layout from "@components/layout";
import Header from "@components/content/header";
import Button from "@components/button";

function ContactPage() {
  const inputClass = `input input-text rounded-md px-4 py-4 bg-layer-spruce-05 transition ease-in-out text-f6 sm:text-f5 md:text-f4`;

  return (
    <React.Fragment>
      <Layout key={`contact`}>
        <Header title="Contact" />
        <article className={`grid grid-cols-12 content gap-y-8`}>
          <p className="col-span-12 lg:col-start-1 lg:col-span-5 mb-12 md:py-16">You can email me at <a href="mailto:hi@mcknny.com" className="text-melon hover:text-sidecar transition">hi@mcknny.com</a> but feel free to use the contact form.</p>
          <form method="post" action="/success" netlify-honeypot="number" data-netlify="true" name="contact" className="grid grid-cols-1 col-span-12 lg:col-start-7 lg:col-span-6 shadow-layer-spruce-03 rounded-xl overflow-hidden bg-layer-spruce-02">
            <input type="hidden" name="number" />
            <input type="hidden" name="form-name" value="contact" />
            <div className="flex flex-col gap-8 px-8 sm:px-12 md:px-16 py-10 md:py-16">
              <fieldset className="border-none m-0 p-0 col-span-1 flex flex-col gap-4">
                <label className="block text-f6 sm:text-f5 md:text-f4 font-bold leading-none" htmlFor="name">Name</label>
                <input type="text" name="name" id="name" className={`${inputClass}`} required />
              </fieldset>
              <fieldset className="border-none m-0 p-0 col-span-1 flex flex-col gap-4">
                <label className="block text-f6 sm:text-f5 md:text-f4 font-bold leading-none" htmlFor="email">Email</label>
                <input type="email" name="email" id="email" className={`${inputClass}`} required />
              </fieldset>
              <fieldset className="border-none m-0 p-0 col-span-1 flex flex-col gap-4">
                <label className="block text-f6 sm:text-f5 md:text-f4 font-bold leading-none" htmlFor="message">Message</label>
                <textarea name="message" id="message" rows="5" className="input input-textarea rounded-md px-4 py-4 bg-layer-spruce-05 transition ease-in-out text-f6 sm:text-f5 md:text-f4" required></textarea>
              </fieldset>
            </div>
            <div className="flex justify-end bg-spruce shadow-layer-spruce-03 px-8 sm:px-12 md:px-16 py-8 lg:py-12">
              <Button look="bold" icon="false" type="submit">
                Send message
              </Button>
            </div>
          </form>
        </article>
      </Layout>
    </React.Fragment>
  );
}

export const Head = () => {
  return <SEO title="Contact" slug="contact" />;
}

export default ContactPage;
