import Story from "@components/card/story";
import Screen from "@components/screen";
import H from "@components/content/h";
import List from "@components/content/list";
import P from "@components/content/p";
import * as React from 'react';
export default {
  Story,
  Screen,
  H,
  List,
  P,
  React
};