import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

// components
import Button from "@components/button";

const Private = ({ netlifyIdentity }) => {
  return (
    <div className={`grid layout full py-48 md:pt-64 pb-24 place-items-center place-content-center relative make-seamless`}>
      <section className={`content max-w-[648px] shadow-layer-spruce-03 rounded-xl overflow-hidden`} id={`private`}>
        <div className={`bg-layer-spruce-02 px-8 sm:px-12 md:px-16 py-10 md:py-16 flex flex-col gap-4`}>
          <h1 className={`lowercase text-melon l-f2 m-0`}>Private project</h1>
          <p className={`m-0`}>This project is currently requires a login to access. You’re more than welcome to view the project by requesting access.</p>
        </div>
        <div className={`flex justify-between bg-spruce shadow-layer-spruce-03 px-8 sm:px-12 md:px-16 py-4`}>
          <Link to={`/contact/`} className={`font-bold font-action text-f6 sm:text-f5 md:text-f4 lowercase py-4 text-pearl hover:text-jasmine transition`}>
            Request access
          </Link>
          <Button onClick={() => { netlifyIdentity.open(); }}>
            Login to view
          </Button>
        </div>
      </section>
    </div>
  )
}

Private.propTypes = {
  netlifyIdentity: PropTypes.object.isRequired,
}

export default Private;
