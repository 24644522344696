/* eslint react/prop-types: 0 */
import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

const Title = ({children, animate, ...props}) => {
  const container = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: .1,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
      },
    },
  }

  const item = {
    visible: wordI => ({
      y: 0,
      transition: {
        delay: wordI * .05,
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: .6,
      },
    }),
    hidden: { y: 200 },
  }


  return (
    <React.Fragment>
      {animate === true &&
        <motion.h1
          {...props}
          initial="hidden"
          animate="visible"
          variants={container}
          key={children}
          aria-label={children}
        >
          {children.split(" ").map((word, wordI) => (
            <motion.span
              key={`word-${word}-${wordI}`}
              style={{
                display: "inline-block"
              }}
              custom={wordI}
              animate="visible"
              variants={item}
            >
              {word === " " ? "\u00A0" : word}
              {"\u00A0"}
            </motion.span>
          ))}
        </motion.h1>
      }
      {animate === false && <h1 {...props}>{children}</h1>}
    </React.Fragment>
  )
}

Title.defaultProps = {
  animate: false
}

Title.propTypes = {
  animate: PropTypes.bool
}

export default Title;
