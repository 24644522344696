import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

// css
import * as styles from "./action.module.css";

// icons
import Arrow from "@icons/arrow-right.inline.svg";

const Action = props => {
  const actionClass = `action relative text-f6 sm:text-f5 md:text-f4 font-bold text-melon hover:text-jasmine min-w-min flex items-center gap-2 font-action lowercase ${styles.action} ${props.className}`;

  return (
    <React.Fragment>
      <Link to={props.to} className={actionClass}>
        {props.text}
        <span className={`bg-madison rounded-full w-6 h-6 sm:w-8 sm:h-8 flex flex-shrink-0 items-center justify-center relative ${styles.icon}`}>
          <Arrow />
        </span>
      </Link>
    </React.Fragment>
  )
}

Action.defaultProps = {
  to: "/portfolio/",
  text: "Find out more"
}

Action.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string,
  className: PropTypes.node,
}

export default Action;
