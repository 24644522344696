import React from "react";
import PropTypes from "prop-types";
import Title from "@components/content/title";

const Header = ({ title }) => {
  return (
    <React.Fragment>
      <header className="pt-[7.25rem] pb-16 md:pb-[8.25rem] sm:mt-[7.5rem] content">
        <Title className="relative z-10 text-f2 xs:text-f2.5 md:text-f1 xl:text-headline overflow-y-hidden mb-0" aria-label={title}>
          {title}
        </Title>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
}

export default Header;
