/* eslint react/prop-types: 0 */
import React, { useContext } from "react";
import { graphql, Link } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";

// debug
// import Dump from "@components/dump";

// components
import SEO from "@components/seo";
import { IdentityContext } from "@components/layout/identity";
import Secret from "@components/content/secret";
import Title from "@components/content/title";

// page level components
// import TableOfContents from "@components/nav/contents";
import Action from "@components/button/action";
import H from "@components/content/h";
import List from "@components/content/list";
import P from "@components/content/p";
import Story from "@components/card/story";
import Screen from "@components/screen";

// css
import * as styles from "./single.module.css";

const shortcodes = {
  Link,
  Action,
  Screen,
  Story,
  P,
  H,
  List } // Provide common components here

export default function Single({ data: { mdx } }) {
  const {
    url,
    service,
    category,
    introduction,
    title,
    description,
    status } = mdx.frontmatter;

  const { user, identity: netlifyIdentity } = useContext(IdentityContext);

  // const {
  //   tableOfContents
  // } = mdx;

  const headingStyles = `relative z-10 mb-4 sm:mb-6 pr-4 text-f2 lg:text-f1 leading-none md:w-7/12 overflow-y-hidden pb-2`;
  const titleStyles = `block text-f7 md:text-f6 text-pearl font-title font-title-wide-medium lowercase`;

  const createSeparator = function() {
    return {__html: "/"};
  }

  return (
    <React.Fragment>
      {/*<TableOfContents contents={tableOfContents} />*/}
      <main className={`single`} key={`single-${title}`}>
        { (status === "published" || (status === "secret" && user) )  &&
          <React.Fragment>
            <header className="grid layout full py-48 md:py-64 lg:py-0 lg:screen lg:screen-rows relative">
              <div className={`content lg:screen-center`} id={`brief`}>
                <Title className={headingStyles} aria-label={description}>{description}</Title>
                <div className={`flex flex-col md:grid md:grid-cols-12 gap-12`}>
                  <div className={`${styles.introduction} col-span-7`}>
                    {introduction.map((p, i) => (
                      <p className={`md:text-f3`} key={i}>{p}</p>
                    ))}
                  </div>
                  <ul className={`mx-0 mt-0 mb-8 sm:mb-4 p-0 text-f7 md:text-f6 font-title font-title-wide-medium lowercase flex flex-col md:col-start-9 md:col-span-4 gap-x-6 gap-y-2 md:pt-2`}>
                    <li className={`flex gap-4 text-melon`}>
                      For
                      {url ?
                        <a href={url} className={titleStyles}>{title}</a> :
                        <span className={titleStyles}>{title}</span>
                      }
                    </li>
                    <li className={`flex gap-4 text-melon`} key="s">
                      Role
                      <ul className={`flex flex-wrap gap-x-4 text-pearl p-0`}>
                      {service.map((name, i) => [
                        <React.Fragment key={i}>
                          <li>
                            {name}
                          </li>
                          {i < service.length - 1 ? <li className="separator"  dangerouslySetInnerHTML={createSeparator()}/> : ``}
                        </React.Fragment>
                      ])}
                      </ul>
                    </li>
                    <li className={`flex gap-4 text-melon`} key="t">
                      Type
                      <ul className={`flex flex-wrap gap-x-4 text-pearl p-0`}>
                      {category.map((name, i) => [
                        <React.Fragment key={i}>
                          <li>
                            {name}
                          </li>
                          {i < category.length - 1 ? <li className="separator"  dangerouslySetInnerHTML={createSeparator()}/> : ``}
                        </React.Fragment>
                      ])}
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </header>

            <article className={`single ${styles.article} grid layout full`}>
              <MDXProvider components={shortcodes}>
                <MDXRenderer>{mdx.body}</MDXRenderer>
              </MDXProvider>
            </article>
          </React.Fragment>
        }
        {(status === "secret" && !user) &&
          <Secret headingStyles={headingStyles} netlifyIdentity={netlifyIdentity} />
        }
      </main>
    </React.Fragment>
  )
}

export const pageQuery = graphql`
  query projectQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      fields {
        slug
      }
      frontmatter {
        title
        description
        service
        category
        url
        introduction
        status
      }
      tableOfContents(maxDepth: 2)
    }
  }
`;

export const Head = ({ data: { mdx } }) => {
  return <SEO title={mdx.frontmatter.title} slug={mdx.fields.slug} />;
}
