import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

// css
import * as styles from "./action.module.css";

// icons
import Arrow from "@icons/arrow-right.inline.svg";

const Button = ({ children, to, as, className, look, icon, ...other }) => {
  const theme = (look === "subtle" ? `${styles.subtle} bg-transparent text-melon` : `${styles.bold} bg-melon hover:bg-jasmine text-madison  lg:leading-[1.1428571429] lowercase px-5 md:px-8 py-2 md:py-3 rounded-md` );

  let iconArrow;

  if (icon === true) {
    iconArrow = <span className={`sm:bg-madison rounded-full sm:w-8 sm:h-8 flex items-center justify-center relative ${styles.icon}`}><Arrow /></span>;
  }

  // Use Link for links, and <button> for others
  if (as === "link") {
    return (
      <Link
        to={to}
        className={`${styles.action} ${theme} ${className}`}
        {...other}
      >
        {children}
        {iconArrow}
      </Link>
    )
  }
  return (
    <button className={`${styles.action} ${theme} ${className}`} {...other}>
      {children}
      {iconArrow}
    </button>
  )
}

export default Button;

Button.defaultProps = {
  to: "/contact",
  children: "Let’s work together",
  as: "button",
  className: "button relative text-f6 sm:text-f5 md:text-f4 flex items-center gap-2 font-bold font-action lowercase",
  look: "subtle",
  icon: true,
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  as: PropTypes.string,
  look: PropTypes.string,
  icon: PropTypes.bool,
}
