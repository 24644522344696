import React from "react";
import { Link } from "gatsby";

// components
import SEO from "@components/seo";
import Layout from "@components/layout";

function DesigningPage() {
  return (
    <React.Fragment>
      <Layout key={`roadmap`}>
        <header className="py-64 mt-8 md:mt-12 lg:mt-24 content">
          <h1 className="relative z-10 mb-0 text-f2 xs:text-f1 xxl:text-headline">Roadmap</h1>
        </header>
        <article className={`content max-w-full`}>
          <h2 className="text-melon mt-0 mb-4">Designing in the open</h2>
          <p className="max-w-xl">This page like much of this website is currently a work in progress. For anyone stumbling across this portfolio, they will likely see a largely complete design. But may find bugs and a lot incomplete bits of content.</p>
          <p className="max-w-xl">It’s not a great reflection—but a busy life leads to slow but steady progress. Over the coming <em>months</em>, I hope to complete more portfolio & content pages, refine the poorer parts of the design and layouts.</p>
          <p className="max-w-xl mb-8">Overall leading to a complete portfolio, with messaging that reflects my skill & ability to deliver you a wonderful website, that serves your customers.</p>

          <h2 className="text-melon mt-8 mb-4">Tasks</h2>
          <h3 className="mb-0 mb-2 text-f3">General</h3>
          <ul>
            <li><s>Complete the task list</s></li>
            <li><s>Make this page more readable</s></li>
            <li>Improve site performance</li>
            <li className="mb-4">Complete alt & figcaptions where necessary</li>
          </ul>

          <h3 className="mb-0 mb-2 text-f3">Content</h3>
          <ul>
            <li><s>Choose the 8 case studies I will work on</s></li>
            <li><s>Create content for each portfolio</s></li>
            <li>Complete 8 portfolio case studies</li>
            <li><s>Create images for each case study</s></li>
            <li>Complete the about page content</li>
            <li>Complete the about page design</li>
            <li>Complete the about page build</li>
            <li><s>Complete the process page content</s></li>
            <li>Complete the process page design</li>
            <li>Complete the process page build</li>
            <li className="mb-4">Complete the contact page</li>
          </ul>

          <h3 className="mb-0 mb-2 text-f3">Design / functionality</h3>
          <ul>
            <li><s>Refine the responsive layout on <Link to="/work/" className="text-jasmine hover:text-sidecar">work</Link> at small screen sizes</s></li>
            <li>Learn how to use framer motion more effectively</li>
            <li><s>Add authentication using Netlify Identity for specific pages</s></li>
            <li>Refine the authentication request design</li>
            <li>
              Create a better homepage flow
              <ul>
                <li className="pl-4">Break up the introduction and make it feel less like a text wall</li>
                <li className="pl-4">Feature work better</li>
              </ul>
            </li>
          </ul>
        </article>
      </Layout>
    </React.Fragment>
  );
}

export const Head = () => {
  return <SEO title="Designing in the open" slug="roadmap" />;
}

export default DesigningPage;
