import React from "react";
import PropTypes from "prop-types";
// import Dump from "@components/dump";

// css
import * as base from "./card.module.css";

const Story = (props) => {
  const { className, children, style } = props;
  const classNameStory = `card p-8 md:p-16 shadow-layer-spruce-03 rounded-xl  bg-layer-spruce-02 ${className}`;

  return (
    <React.Fragment>
      {/*<Dump individualProjectProps={props.image.childImageSharp} />*/}
      <article className={`${classNameStory} ${base.card}`} style={style}>
        {children}
      </article>
    </React.Fragment>
  )
}

Story.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  props: PropTypes.node,
  style: PropTypes.object
}

export default Story;
