import React from "react";
import SEO from "@components/seo";
import { StaticQuery, graphql } from "gatsby";

// components
import Layout from "@components/layout";
import Action from "@components/button/action";

// sections
import Hero from "@components/hero";
import Landscape from "@components/card/landscape";

// css
import * as styles from "@components/card/project.module.css";

function IndexPage() {
  return (
    <React.Fragment>
      <Layout key={`home`}>
        <Hero link="/work/" />
        <section className={`grid layout full gap-y-24 pt-24`}>
          <StaticQuery
            query={graphql`
              query homeProjects {
                allMdx(
                  filter: {
                    frontmatter: {
                      status: {
                        in: ["published"]
                      },
                      title: {
                        in: ["Studio by Silentnight", "Fresh Ancoats Foods", "Cabana"]
                      }
                    }
                  }
                ) {
                  nodes {
                    id
                    frontmatter {
                      description
                      service
                      status
                      icon
                      image {
                        childImageSharp {
                          gatsbyImageData(
                            layout: CONSTRAINED,
                            formats: [AUTO, WEBP],
                            quality: 98,
                            transformOptions: {
                              fit: FILL, cropFocus: CENTER
                            }
                          )
                        }
                      }
                      landscape {
                        childImageSharp {
                          gatsbyImageData(
                            layout: CONSTRAINED,
                            formats: [AUTO, WEBP],
                            width: 1920,
                            height: 1124,
                            quality: 100,
                            transformOptions: {
                              fit: FILL, cropFocus: CENTER
                            }
                          )
                        }
                      }
                      title
                    }
                    fields {
                      slug
                    }
                  }
                }
              }
            `}
            render={data => (
              <React.Fragment>
                {data.allMdx.nodes.map(({ fields, frontmatter, id }) => (
                  <React.Fragment key={id}>
                    <Landscape
                      className={`full ${styles.project}`}
                      link={fields.slug}
                      frontmatter={frontmatter} />
                  </React.Fragment>
                ))}
              </React.Fragment>
            )}
          />
          <Action to={`work`} className="col-content-start col-content-end" text="View all work" />
        </section>
      </Layout>
    </React.Fragment>
  );
}

export const Head = () => {
  return <SEO title="Visual and UX design" slug="/" />;
}

export default IndexPage;
