import React from "react";

// components
import SEO from "@components/seo";
import Layout from "@components/layout";

function NotFoundPage() {
  return (
    <React.Fragment>
      <Layout key={`four-zero-four`}>
        <header className="py-64 mt-8 md:mt-12 lg:mt-24 content">
          <h1 className="relative z-10 mb-0 text-f2 xs:text-f1 xxl:text-headline">404</h1>
        </header>
        <article className={`content max-w-full`}>
          <h2 className="m-0 pb-4">Page not found</h2>
          <p>Somehow you’ve stumbled across a page that doesn’t exist.</p>
        </article>
      </Layout>
    </React.Fragment>
  );
}


export const Head = () => {
  return <SEO title="Page not found" slug="404" />;
}

export default NotFoundPage;
