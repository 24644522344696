import React from "react";
import PropTypes from "prop-types";

function Layout({ className, children }) {
  return (
    <React.Fragment>
      <main className={className}>
        {children}
      </main>
    </React.Fragment>
  );
}

Layout.defaultProps = {
  className: "grid layout w-full",
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node.isRequired,
}

export default Layout;
