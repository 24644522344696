import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { motion } from "framer-motion";

// css
import * as styles from "./tabbar.module.css";

// icons
import Home from "@icons/home.inline.svg";
import Portfolio from "@icons/portfolio.inline.svg";
// import About from "@icons/about.inline.svg";
import Process from "@icons/process.inline.svg";
import Contact from "@icons/contact.inline.svg";

const Tabbar = ({ links }) => {
  const navStyles = `${styles.tabbar} z-50 fixed bottom-0 right-0 left-0 flex justify-around md:hidden w-screen`;
  const linkStyles = `${styles.link} text-harp flex flex-col items-center text-f8 font-bold no-underline`;

  return (
    <React.Fragment>
      <motion.nav 
        className={navStyles}
        initial={{ opacity: 0, y: 120 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          ease: [0.6, 0.01, -0.05, 0.9],
          duration: 1
        }}
      >
        {links.map((link) => (
          <Link
            className={linkStyles}
            key={link.title}
            to={link.route}
            activeClassName={`text-tumbleweed`}
          >
            {link.title === "home" && <Home />}
            {link.title === "work" && <Portfolio />}
            {link.title === "process" && <Process />}
            {/* link.title === "about" && <About /> */}
            {link.title === "contact" && <Contact />}
            {link.title}
          </Link>
        ))}
      </motion.nav>
    </React.Fragment>
  );
};

Tabbar.propTypes = {
  logo: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
};

export default Tabbar;
