import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

// components
import Action from "@components/button/action";
import Title from "@components/content/title";

// style
import * as styles from "./hero.module.css";

// svg
import Eraser from "@images/eraser.inline.svg";
import Palomino from "@images/palomino.inline.svg";
import Pen from "@images/pen.inline.svg";
import Pencil from "@images/pencil.inline.svg";
import Sharpener from "@images/sharpener.inline.svg";

const Hero = (props) => {
  const heroStyles = `${props.className} ${styles.container} grid subgrid-cols items-center col-container-start col-container-end relative xxl:mb-5`;
  const headingStyles = `relative z-10 font-display l-headline leading-[.875] tracking-[-.0167em] overflow-hidden mb-8 xxl:mb-2`;

  const heroTitle = `Let’s make your user experience <span class="text-melon">go&nbsp;further</span>`;
  const heroDesc = `Hi, I’m Steve, since 2012 I’ve designed websites for all sorts of companies. Over time, my way of working has shifted to continuous improvement. It’s where I offer the most value.`;
  // const [hidden, setHidden] = useState(false);

  const controls = useAnimation();
  const variants = {
    hidden: { opacity: 0, y: 16 },
    visible: { opacity: 1, y: 0 },
  };
  const [ref, inView] = useInView({
    threshold: .15
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);


  return (
    <React.Fragment>
      <motion.section
        className={`${heroStyles}`}
        exit={{ opacity: 0 }}
        id="page-title">
        <div className={`${styles.content} col-content-start col-content-end z-10 relative xxl:flex items-start xxl:items-end xxl:gap-12`}>
          <Title className={`${headingStyles}`} aria-label="Let’s make your user experience go further" dangerouslySetInnerHTML={{__html: heroTitle}} />
          <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={variants}
            transition={{
              ease: [0.6, 0.01, -0.05, 0.9],
              delay: .1,
              opacity: { duration: .3 },
              y: { duration: .6 }
            }}
            className="xxl:relative xxl:top-12"
          >
            <p className="mb-6 max-w-[48ch] xxl:mb-8 text-paragraph text-f6 sm:text-f5 md:text-f4 xl:text-f3">{heroDesc}</p>
            <Action to={props.link} text="See the work" />
          </motion.div>
          <Pen className={`illo ${styles.pen}`} role="presentation" />
          <Eraser className={`illo ${styles.eraser}`} role="presentation" />
          <Sharpener className={`illo ${styles.sharpener}`} role="presentation" />
        </div>
        <Palomino className={`illo ${styles.palomino}`} role="presentation" />
        <Pencil className={`illo ${styles.pencil}`} role="presentation" />
      </motion.section>
    </React.Fragment>
  );
};

Hero.propTypes = {
  className: PropTypes.node,
  link: PropTypes.string.isRequired,
};

export default Hero;
