import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

// import Dump from "@components/dump";
// <Dump individualProjectProps={frontmatter} />

// css
import * as styles from "./project.module.css";

const Project = ({ frontmatter, className, link }) => {
  const image = getImage(frontmatter.image.childImageSharp.gatsbyImageData);

  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: .035
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <React.Fragment>
      <motion.article
        className={`project sm:self-start ${styles[frontmatter.status]} ${styles.container} ${className}`}
        ref={ref}
        animate={controls}
        initial="hidden"
        transition={{
          ease: "circOut",
          opacity: { duration: .4 },
          y: { duration: 1 }
        }}
        variants={{
          hidden: { opacity: 0, y: 32 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        <Link to={link} className={`${styles.image} ${styles.portrait} rounded-2xl relative`}>
          <GatsbyImage
            image={image}
            alt={frontmatter.description}
          />
        </Link>
        <div className={`${styles.meta} meta px-8 py-8 lg:pb-0 xl:pt-12 text-center`}>
          <p className="block text-f6 lg:text-f4 text-melon font-medium font-title font-title-wide-medium lowercase mb-1">{frontmatter.title}</p>
          <h2 className="font-headline l-f2 m-0 w-11/12 mx-auto leading-none">
            <Link to={link} className="block">
              {frontmatter.description}
            </Link>
          </h2>
        </div>
      </motion.article>
    </React.Fragment>
  )
}

Project.defaultProps = {
  className: "auto-fit"
};


Project.propTypes = {
  link: PropTypes.string.isRequired,
  className: PropTypes.string,
  frontmatter: PropTypes.shape({
    image: PropTypes.object,
    description: PropTypes.string,
    title: PropTypes.string,
    status: PropTypes.string,
  }),
}

export default Project;
