import React from "react";

// components
import SEO from "@components/seo";
import Layout from "@components/layout";
import Header from "@components/content/header";
import Projects from "@components/content/projects";

const WorkPage = () => {
  return (
    <React.Fragment>
      <Layout key={`work`}>
        <Header title="Work" />
        <section className={`grid layout sm:grid-cols-10 lg:grid-rows-8 full sm:content sm:gap-x-8 lg:gap-x-16`}>
          <Projects orientation="portrait" />
        </section>
      </Layout>
    </React.Fragment>
  )
}

export const Head = () => {
  return <SEO title="Work" slug="work" />;
}

export default WorkPage;
