import React from "react";

// components
import SEO from "@components/seo";
import Layout from "@components/layout";
import Header from "@components/content/header";

// css
import * as styles from "@components/layout/single.module.css";

function ProcessPage() {
  return (
    <React.Fragment>
      <Layout key={`process`}>
        <Header title="Process" />
        <article className={`single ${styles.article} content`}>
          <p className="max-w-3xl">At it’s simplest I adopt a build, measure and learn approach. Which means each project starts with a challenge that has been observed and figuring out how I can improve that for your customers.</p>

          <p className="max-w-3xl">What I like most about the BML approach is its flexibility. Not every project is the same, but every project should have the ability to be validated and measured in its success.</p>

          <p className="max-w-3xl">With the overall aim of having an objective process, it facilitates discussions & collaboration. This leads to happier customers and importantly you.</p>

          <h2 className="max-w-2xl">Let’s get into the preprocess</h2>
          <p className="max-w-2xl">You can’t just dive into a project, as much as your intuition may want to get ahead of itself. There needs to be a plan.</p>

          <div className="md:flex md:space-x-8 pt-16 md:pt-24">
            <div className="flex-1 mb-16">
              <h3>Challenge</h3>
              <p>Identifying the challenges means forming a brief and detailing each individual challenge.</p>
              <p className="text-f6 pt-4">A challenge might look like <span className="text-harp">“why is there such a high drop off rate on a particular phase of the checkout”</span>.</p>
            </div>

            <div className="flex-1 mb-16">
              <h3>Discover</h3>
              <p>Now that the challenge has been indentified, you need to apply enough research to get an understanding of the problem. This can be anything from analysing data to usability testing.</p>
              <p className="text-f6 pt-4">Looking at your data you may see <span className="text-harp">a number of people leaving a particular checkout phase are associated to entering a phone number</span>.</p>
            </div>

            <div className="flex-1 mb-16">
              <h3>Define</h3>
              <p>Finally, that leads to defining the project. Allowing us to establish the problem statement, hypothesis and how success will be measured.</p>
              <p className="text-harp text-f6 pt-4">Now the build can begin.</p>
            </div>
          </div>

          <h2 className="max-w-2xl">Now for the design process</h2>
          <p className="max-w-2xl">Now there’s a plan, how do you validate your ideas? There’s many ways to approach the challenge with varying levels of effort.</p>

          <p className="max-w-2xl">If a project is new, you may start simpler to get feedback earlier. If a project is established, it may need iterating on through more functional design. It all depends on where the project is in its lifecycle.</p>

          <div className="md:flex md:space-x-8 pt-16 md:pt-24">
            <div className="flex-1 mb-16">
              <h3 className="max-w-2xl">Build</h3>
              <ul>
                <li>Sketching</li>
                <li>Wireframes</li>
                <li>Visual design</li>
                <li>Prototyping</li>
                <li>Fully functional</li>
              </ul>
            </div>
            <div className="flex-1 mb-16">
              <h3 className="max-w-2xl">Measure</h3>
              <ul>
                <li>Usability testing</li>
                <li>Split URL test</li>
                <li>Design surveying</li>
                <li>Analytics</li>
                <li>A/B testing</li>
                <li>Customer feedback</li>
              </ul>
            </div>
            <div className="flex-1 mb-16">
              <h3 className="max-w-2xl">Learn</h3>
              <p className="max-w-3xl">The final step—what’s been learnt in the previous stages? Has the idea been validated? Is it ready to be released? Time to document findings and determine the outcome.</p>
            </div>
          </div>
        </article>
      </Layout>
    </React.Fragment>
  );
}

export const Head = () => {
  return <SEO title="Process" slug="process" />;
}

export default ProcessPage;
