import React from "react";
import PropTypes from "prop-types";
import { isBrowser } from "react-device-detect";
import { AnimateSharedLayout, AnimatePresence, MotionConfig } from "framer-motion";

// components
import Header from "@components/header";
import Profile from "@components/sections/profile";
import Footer from "@components/footer";
import Cursor from "@components/cursor";

function Base({ children }) {
  return (
    <React.Fragment>
      {isBrowser && <Cursor />}

      <MotionConfig reducedMotion="user">
        <AnimateSharedLayout type="crossfade" key={`global-shared`}>
          <AnimatePresence key={`global-presence`}>
            <Header className="header" key={`global-header`} />
            {children}
            <Profile className="py-36 grid layout  items-end mdMax:justify-center md:items-center" key={`global-profile`} />
            <Footer key={`global-footer`} />
          </AnimatePresence>
        </AnimateSharedLayout>
      </MotionConfig>
    </React.Fragment>
  );
}

Base.defaultProps = {
  className: "grid layout",
}

Base.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node.isRequired,
}

export default Base;
