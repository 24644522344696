import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

// components
// import Dump from "@components/dump";
import Project from "@components/card/project";
import Landscape from "@components/card/landscape";

// css
import * as styles from "./projects.module.css";

const Projects = ({ orientation }) => {

  // 664x708
  return (
    <StaticQuery
      query={graphql`
        query listProjects {
          allMdx(
            sort: { fields: [frontmatter___priority], order: ASC }
            filter: { frontmatter: { status: { in: ["secret", "published"] } } }
            limit: 12
          ) {
            nodes {
              id
              frontmatter {
                description
                service
                status
                icon
                image {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      formats: [AUTO, WEBP],
                      quality: 98,
                      transformOptions: {
                        fit: FILL, cropFocus: CENTER
                      }
                    )
                  }
                }
                landscape {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      formats: [AUTO, WEBP],
                      width: 1920,
                      height: 1124,
                      quality: 100,
                      transformOptions: {
                        fit: FILL, cropFocus: CENTER
                      }
                    )
                  }
                }
                title
              }
              fields {
                slug
              }
            }
          }
        }
      `}
      render={data => (
        <React.Fragment>
          {data.allMdx.nodes.map(({ fields, frontmatter, id }) => (
            <React.Fragment key={id}>
              {
                orientation === 'landscape' &&
                <Landscape
                  className={`full ${styles.project}`}
                  link={fields.slug}
                  frontmatter={frontmatter} />
              }
              {
                orientation === 'portrait' &&
                <Project
                  className={`full sm:col-span-5 ${styles.project}`}
                  link={fields.slug}
                  frontmatter={frontmatter} />
              }
            </React.Fragment>
          ))}
        </React.Fragment>
      )}
    />
  )
}

Projects.defaultProps = {
  orientation: `portrait`,
}

Projects.propTypes = {
  data: PropTypes.node,
  orientation: PropTypes.string.isRequired,
}

export default Projects;
